export const DEFAULT_FONT = 'DIN';

export const INSTALLATION_ONGOING_COLOR = '#8F8F95';
export const NOT_CONNECTED_STATUS_COLOR = '#CE181E';
export const CONNECTED_COLOR = '#1F202B';

export const OK_COLOR = '#1F202B';
export const WARNING_COLOR = '#E5A5A8';
export const ERROR_COLOR = '#DA6468';
export const NOT_CONNECTED_MONITORING_COLOR = '#CE181E';

export const PIE_CHART_COLORS = {
  countColor: '#FFFFFF',
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: 'rgba(31, 32, 43, 0.50)',
  titleColor: '#000',
  bgColor: '#fff'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: 'rgba(31, 32, 43, 0.50)',
  titleColor: '#000',
  bgColor: '#fff',
  OVERALL_ACTIVE_COLOR: '#F53920',
  ONE_YEAR_ACTIVE_COLOR: '#DA6468',
  ONE_MONTH_ACTIVE_COLOR: '#E5A5A8',
  OVERALL_EMPTY_COLOR: 'rgba(109, 110, 113, 0.09)',
  ONE_YEAR_EMPTY_COLOR: 'rgba(109, 110, 113, 0.09)',
  ONE_MONTH_EMPTY_COLOR: 'rgba(109, 110, 113, 0.09)'
};

export const tooltip = {
  backgroundColor: '#f8f8f8',
  style: {
    color: '#000',
    fontSize: '16px'
  }
};
