export const multiselectConfig = {
  multiselectContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 'min-content',
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '38px'
  },
  searchBox: {
    width: '100%',
    border: 'none',
    backgroundColor: 'rgba(31, 32, 43, 0.10)'
  },
  chips: {
    backgroundColor: '#fff',
    color: '#000',
    whiteSpace: 'normal'
  },
  optionContainer: {
    position: 'absolute',
    width: '100%',
    border: 'none',
    backgroundColor: '#fff',
    top: '4px'
  },
  option: {
    color: '#000'
  },
  inputField: {
    maxWidth: '240px'
  }
};

export const READ_ONLY_ROLES = ['pv_installer_employee_read_only', 'oem_employee_read_only'];
