import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeRctID,

  // R
  reqSMID: view.reqRctID,

  // S
  SMID: view.rctID,
  SMIDTolltip: view.rctIDTooltip,
  nameOrSmid: view.nameOrRctID,
  nameOrSmidPlaceholder: view.nameOrRctIdPlaceholder,
  smidLabel: view.rctIdLabel
};

export default replace;
