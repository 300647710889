import React, { memo } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import './index.scss';

const CancelButton = memo((props) => {
  const { onClickHandler, i18nLabel = 'cancel', customButtonClass = '', type, disabled } = props;
  const isExistIcon = !!type;
  const buttonOptions = {
    upload: {
      iconClassName: 'la la-download download-icon',
      label: 'uploadFiles'
    },
    import: {
      iconClassName: 'la la-upload',
      label: 'uploadFiles'
    }
  };

  const { iconClassName } = buttonOptions?.[type] || '';

  return (
    <button
      type="button"
      data-cy="cancel-button"
      className={`cancel-button ${customButtonClass ?? ''}`}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {isExistIcon && (
        <>
          <i className={iconClassName} />
          {' '}
        </>
      )}
      {i18n.t(i18nLabel)}
    </button>
  );
});

CancelButton.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  i18nLabel: PropTypes.string,
  customButtonClass: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string
};

export default CancelButton;
